import { Star } from "@mui/icons-material";
import { upsertLocationRating } from "../../backendclient";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import './LocationRating.css';
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

function RatingStar({value, currentRatingValue, onMouseEnter, onMouseLeave, onClick, color}) {
    return <div 
        className="LocationRatingStar"
        size="small"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => onClick(value)}
        style={{
            color: currentRatingValue >= value ? color : "gray"
        }}
    >
        <Star fontSize="small" />
    </div>
}

function LocationRating({currentUser, readOnly, location, verbose, style}) {
    const averageRating = location?.ratings?.length > 0 ? (location.ratings.reduce((t, v) => t + v.value, 0) / location.ratings.length) : null;
    const [userRating, setUserRating] = useState(null);
    const [hoveredRating, setHoveredRating] = useState(null);

    useEffect(() => {
        for (const rating of location?.ratings || []) {
            if (rating.userId === currentUser?.id) {
                setUserRating(rating.value);
                return;
            }
        }
        setUserRating(null);
    }, [location, currentUser]);

    const ratingToDisplay = hoveredRating || userRating || averageRating || 0;
    const ratingColor = hoveredRating || userRating ? "gold" : "#1976d2";

    async function onRatingClick(value) {
        setUserRating(value);
        await upsertLocationRating(currentUser?.id, location?.id, value);
    }

    return <span className="LocationRating" style={{cursor: hoveredRating ? 'pointer' : undefined, ...style}}>
        {[0.2, 0.4, 0.6, 0.8].map(v => <RatingStar 
            key={v} 
            value={v} 
            onClick={!readOnly ? onRatingClick : null} 
            onMouseEnter={!readOnly ? () => setHoveredRating(v) : null}
            onMouseLeave={!readOnly ? () => setHoveredRating(null) : null}
            currentRatingValue={ratingToDisplay}
            color={ratingColor}
            />)}
        {verbose && <div><Typography variant="caption">(Avg: {Math.round(averageRating * 5 * 10) / 10} from {location?.ratings?.length} ratings)</Typography></div>}
    </span>
}

const LocationRatingWrapper = (props) => <CurrentUserContext.Consumer>
    {currentUserContextValue => <LocationRating currentUser={currentUserContextValue} {...props} />}
</CurrentUserContext.Consumer>;;

export default LocationRatingWrapper;